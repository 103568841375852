@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Poppins", "sans-serif",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

svg > g > g.google-visualization-tooltip {
  pointer-events: none;
}

html {
  overflow-y: hidden;
}

@font-face {
  font-family: "Poppins-Black";
  src: url("./assets/fonts/Poppins-Black.ttf");
}
@font-face {
  font-family: "Poppins-Medium";
  src: url("./assets/fonts/Poppins-Medium.ttf");
}

@font-face {
  font-family: "Poppins-Bold";
  src: url("./assets/fonts/Poppins-Bold.ttf");
}
@font-face {
  font-family: "Poppins-SemiBold";
  src: url("./assets/fonts/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: "Poppins-Light";
  src: url("./assets/fonts/Poppins-Light.ttf");
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-Regular.ttf");
}

@font-face {
  font-family: "Poppins-Thin";
  src: url("./assets/fonts/Poppins-Thin.ttf");
}

/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e0e0e0;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #bdbdbd;
}

::placeholder {
  color: #757575;
  opacity: 0.5; /* Firefox */
}

::-ms-input-placeholder { /* Edge 12 -18 */
  color: #757575;
  opacity: 0.5;
}