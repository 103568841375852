.cursor-pointer {
  cursor: pointer;
}

.bg-selected .first-path{
  fill:#9cdffe;
}
.bg-hover:hover .first-path{
  fill: #9cdffe;

}

.cursor-disable {
  cursor: not-allowed;
}
