.Toastify__close-button {
  display: none;
}

.Toastify__toast {
  padding: 0 !important;
  min-height: auto !important;
}

.Toastify__toast-body {
  padding: 0 !important;
}
