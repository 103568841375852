.visual-requirements .quill-editor{
    height: calc(100% - 41px);
    border: 0 !important;
}

.visual-requirements .quill-editor .ql-toolbar{
    border : 0;
    border-bottom: 1px solid #dddddd;
}

.visual-requirements .quill-editor .ql-container{
    border: 0;
    font-family: "Poppins";
    font-size: "16px";
    font-weight: 500,
}

.quill > .ql-container > .ql-editor.ql-blank::before{
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    color: #757575;
    opacity: 0.5;
    font-size: 14px;
}