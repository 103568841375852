.business-requirements .quill-editor{
    min-height: calc(100% - 66px);
    max-height: 400px;
    border: 0;
}

.business-requirements .quill-editor .ql-toolbar{
    border-radius: 8px 8px 0px 0px;
    border-bottom: 1px solid #dddddd;
}

.business-requirements .quill-editor .ql-container{
    border-radius: 0px 0px 8px 8px;
    font-family: "Poppins";
    font-size: "16px";
    font-weight: 500,
}

.quill > .ql-container > .ql-editor.ql-blank::before{
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    color: #757575;
    opacity: 0.5;
    font-size: 14px;
}