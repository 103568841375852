.calendarWrap {
  font-family: 'Poppins', sans-serif;
  display: inline-block;
  position: relative;
}

.calendarBox {
  position: fixed;
  margin-top: 10px;
  transform: translateX(-57%);
  display: flex;
  align-items: center;
  z-index: 999 !important;
}

.calendarElement {
  /* // border-radius: 8px; */
  /* // border: 1px solid var(--Surface-Variant, #f2f5ff); */
  /* // box-shadow: 0px 10px 20px 0px rgba(18, 38, 63, 0.3); */
}

.rdrDefinedRangesWrapper {
  width: 111px;
}

.rdrInputRanges {
  display: none;
}

.rdrDateDisplayWrapper {
  background-color: white;
}

.rdrNextPrevButton {
  background-color: #fffbe6;
}

.rdrNextPrevButton:hover {
  background-color: #ffc53d;
}

rdrMonthAndYearPickers select:hover {
  background-color: #fffbe6;
}

.rdrDayToday .rdrDayNumber span:after {
  background: #ffc53d;
}

.rdrStaticRangeLabel,
.rdrMonthAndYearPickers select,
.rdrDateDisplay input {
  font-family: 'Poppins', sans-serif;
}